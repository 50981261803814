import React from "react";
import Footer from "./Footer";
import Header from "./Header";
import Sidebar from "./Sidebar";
import GetApiCall from "./GetApi";
import moment from 'moment';

import Notiflix from "notiflix-react";
import PostApiCall from "./Api"


class Login extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            NumRegex: /^[0-9]*$/,
            isPasswordVisible: false,
            AlphaNumericRegex: /^[a-zA-Z0-9]*$/,
            EmailRegex: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
            UrlRegex: /^(https:\/\/www\.|httpss:\/\/www\.|https:\/\/|httpss:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/,
            open: false,
            EnteredOtp: '',
            MailerOtp: '',
            Email: '',
            openOtp: false,
            openPassword: false,
            Password: '',

            Salt: [],
            isNewPasswordVisible: false,
            isConPasswordVisible: false,
            Name: '',
            MobileNo: '',
            EmailAddress: '',
            RegisterPassword: '',
            ConfirmPassword: '',
        }
    }

    saveLogin() {
        if (this.state.Email != '') {
            if (this.state.EmailRegex.test(this.state.Email)) {
                if (this.state.Password != '') {
                    this.onLogin();

                }
                else {
                    Notiflix.Notify.Failure('Please enter your password.')
                }
            }
            else {
                Notiflix.Notify.Failure('Please enter valid email.')
            }
        }
        else {
            Notiflix.Notify.Failure('Please enter your email.')
        }
    }

    onLogin = () => {

        Notiflix.Loading.Dots('Please wait...');
        PostApiCall.postRequest(
            {
                email: this.state.Email,
                password: this.state.Password,
                action: 'Login',
                actiondate: moment().format('lll'),
            }, "AddwebsiteLogin").then((results) =>

                // const objs = JSON.parse(result._bodyText)
                results.json().then(obj => {
                    if (results.status == 200 || results.status == 201) {
                        localStorage.setItem('CustomerData', JSON.stringify(obj.data))
                        Notiflix.Loading.Remove()
                        window.location.href = "/"
                    }
                    else {
                        Notiflix.Loading.Remove()
                        Notiflix.Notify.Failure(obj.data)
                    }
                })
            )
    }

    render() {
        return (
            <div>
                <Header></Header>
                <div class="osahan-signin">
        <div class="border-bottom p-3 d-flex align-items-center">
            <a class="font-weight-bold text-success text-decoration-none" href="account-setup.html"><i class="icofont-rounded-left back-page"></i></a>
            <a class="toggle ml-auto" href="#"><i class="icofont-navigation-menu"></i></a>
        </div>
        <div class="p-3">
            <h2 class="my-0">Welcome Back</h2>
            <p class="small">Sign in to Continue.</p>
            <form action="./">
                <div class="form-group">
                    <label for="exampleInputEmail1">Email</label>
                    <input placeholder="Enter Email" type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp"/>
                </div>
                <div class="form-group">
                    <label for="exampleInputPassword1">Password</label>
                    <input placeholder="Enter Password" type="password" class="form-control" id="exampleInputPassword1"/>
                </div>
                <button type="submit" class="btn btn-success btn-lg rounded btn-block">Sign in</button>
            </form>
            <p class="text-muted text-center small m-0 py-3">or</p>
            <a href="./" class="btn btn-dark btn-block rounded btn-lg btn-apple">
                <i class="icofont-brand-apple mr-2"></i> Sign up with Apple
            </a>
            <a href="./" class="btn btn-light btn-block rounded btn-lg btn-google">
                <i class="icofont-google-plus text-danger mr-2"></i> Sign up with Google
            </a>
        </div>
    </div>
                

    <div class="osahan-fotter fixed-bottom">
        <a href="/register" class="btn btn-block btn-lg bg-white">Don't have an account? Sign up</a>
    </div>


            </div>

        )
    }
}

export default Login;