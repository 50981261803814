import React from "react";
import Footer from "./Footer";
import Header from "./Header";
import Sidebar from "./Sidebar";

class Changepassword extends React.Component {
  render() {
    return (
      <div>
        <Header></Header>
        <section class="section-padding account-bg">
          <div class="container-fluid">
            <div class="row">
              <div class="col-md-3">
                <Sidebar></Sidebar>
              </div>
              <div class="col-md-9">
                <div class="account-main profile">
                  <h5 class="sub-title">Your Password Information</h5>
                  <div class="row">
                    <div class="col-md-6">
                      <label>Old Password</label>
                      <input
                        type="text"
                        placeholder="*******"
                        class="form-control"
                        disabled
                      ></input>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6">
                      <label>New Password</label>
                      <input
                        type="text"
                        placeholder="*******"
                        class="form-control"
                        disabled
                      ></input>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6">
                      <label>Confirm Password</label>
                      <input
                        type="text"
                        placeholder="*******"
                        class="form-control"
                        disabled
                      ></input>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-4">
                      <button class="btn btn-common" disabled>
                        Update Password
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer></Footer>
      </div>
    );
  }
}

export default Changepassword;
