import logo from "./logo.svg";
import "./App.css";
import Slider from "react-slick";
import React from "react";
import Header from "./Header";
import GetApiCall from "./GetApi";

import Notiflix from "notiflix-react";
import PostApiCall from "./Api"
import moment from 'moment';
import Footer from './Footer';


class App extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      Items: [], Products: [],
      productId: null,
      Feature: [],
      items: [],
      isLoaded: false,
      featureProducts: [],
      
    }


  }
  componentDidMount() {

    PostApiCall.postRequest(
      {
        catCount: 2,
      },
      "GetProductCategories"
    ).then((results) =>
      // const objs = JSON.parse(result._bodyText)
      results.json().then((obj1) => {

        if (results.status == 200 || results.status == 201) {
          // console.log(obj1.data)
          this.setState({
            Items: obj1.data,
          });
        }
      })

    );

    // Notiflix.Loading.Dots('Please wait...');
    GetApiCall.getRequest("GetFeaturedProduct").then((resultdes) =>
      resultdes.json().then((obj) => {
        // console.log(obj.data)
        this.setState({
          featureProducts: obj.data,
        });
        Notiflix.Loading.Remove();
      })
    );

   
    
    if (localStorage.getItem("CustomerData")) {
      var login = localStorage.getItem('CustomerData');
      var logindetails = JSON.parse(login)
      // console.log(logindetails)
    }


  }






  render() {
    const settings = {
      dots: true,
      autoplay:true,
      infinite: true,
      arrows: false,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplaySpeed: 4000
    };
    const productslider = {
      dots: false,
      infinite: true,
      arrows: true,
      speed: 500,
      slidesToShow:4,
      slidesToScroll: 1,
    responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              
            }
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              initialSlide: 2
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
        ]
      
      };
    const testimonialslider = {
      dots: false,
      infinite: true,
      arrows: true,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
            dots: true
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    
    };
    return (
      <div className="App fixed-bottom-padding">
        
 

     <Header></Header>
      
      <main>
      <div class="osahan-home-page">
        <div class="border-bottom p-3">
            <div class="title d-flex align-items-center">
                <a href="./" class="text-decoration-none text-dark d-flex align-items-center">
                    <img class="osahan-logo mr-2" src="assets/img/logo.jpg"/>
                   
                </a>
                <p class="ml-auto m-0">
                    <a href="notification.html" class="text-decoration-none bg-white p-1 rounded shadow-sm d-flex align-items-center">
                        <i class="text-dark icofont-notification"></i>
                        <span class="badge badge-danger p-1 ml-1 small">2</span>
                    </a>
                </p>
                <a class="toggle ml-3" href="#" ><i class="icofont-navigation-menu"></i></a>
            </div>
            <a href="/search" class="text-decoration-none">
                <div class="input-group mt-3 rounded shadow-sm overflow-hidden bg-white">
                    <div class="input-group-prepend">
                        <button class="border-0 btn btn-outline-secondary text-success bg-white"><i class="icofont-search"></i></button>
                    </div>
                    <input type="text" class="shadow-none border-0 form-control pl-0" placeholder="Search for Products.." aria-label="" aria-describedby="basic-addon1"/>
                </div>
            </a>
        </div>

        <div class="osahan-body">

            <div class="p-3 osahan-categories">
                <h6 class="mb-2">What do you looking for?</h6>
                <div class="row m-0">
                    <div class="col pl-0 pr-1 py-1">
                        <div class="bg-white shadow-sm rounded text-center  px-2 py-3 c-it">
                            <a href="/Productslists">
                                <img src="assets/img/categorie/1.svg" class="img-fluid px-2"/>
                                <p class="m-0 pt-2 text-muted text-center">Vegetables</p>
                            </a>
                        </div>
                    </div>
                    <div class="col p-1">
                        <div class="bg-white shadow-sm rounded text-center  px-2 py-3 c-it">
                            <a href="/Productslists">
                                <img src="assets/img/categorie/2.svg" class="img-fluid px-2"/>
                                <p class="m-0 pt-2 text-muted text-center">Fruits</p>
                            </a>
                        </div>
                    </div>
                    <div class="col p-1">
                        <div class="bg-white shadow-sm rounded text-center  px-2 py-3 c-it">
                            <a href="/Productslists">
                                <img src="assets/img/categorie/3.svg" class="img-fluid px-2"/>
                                <p class="m-0 pt-2 text-muted text-center">Meat</p>
                            </a>
                        </div>
                    </div>
                    <div class="col pr-0 pl-1 py-1">
                        <div class="bg-white shadow-sm rounded text-center  px-2 py-3 c-it">
                            <a href="/Productslists">
                                <img src="assets/img/categorie/4.svg" class="img-fluid px-2"/>
                                <p class="m-0 pt-2 text-muted text-center">Seafood</p>
                            </a>
                        </div>
                    </div>
                </div>
                <div class="row m-0">
                    <div class="col pl-0 pr-1 py-1">
                        <div class="bg-white shadow-sm rounded text-center  px-2 py-3 c-it">
                            <a href="/Productslists">
                                <img src="assets/img/categorie/5.svg" class="img-fluid px-2"/>
                                <p class="m-0 pt-2 text-muted text-center">Milk & Egg</p>
                            </a>
                        </div>
                    </div>
                    <div class="col p-1">
                        <div class="bg-white shadow-sm rounded text-center  px-2 py-3 c-it">
                            <a href="/Productslists">
                                <img src="assets/img/categorie/6.svg" class="img-fluid px-2"/>
                                <p class="m-0 pt-2 text-muted text-center">Bread</p>
                            </a>
                        </div>
                    </div>
                    <div class="col p-1">
                        <div class="bg-white shadow-sm rounded text-center  px-2 py-3 c-it">
                            <a href="/Productslists">
                                <img src="assets/img/categorie/7.svg" class="img-fluid px-2"/>
                                <p class="m-0 pt-2 text-muted text-center">Frozen</p>
                            </a>
                        </div>
                    </div>
                    <div class="col pr-0 pl-1 py-1">
                        <div class="bg-white shadow-sm rounded text-center  px-2 py-3 c-it">
                            <a href="/Productslists">
                                <img src="assets/img/categorie/8.svg" class="img-fluid px-2"/>
                                <p class="m-0 pt-2 text-muted text-center">Organic</p>
                            </a>
                        </div>
                    </div>
                </div>
            </div>

            <div class="py-3 bg-white osahan-promos shadow-sm">
                <div class="d-flex align-items-center px-3 mb-2">
                    <h6 class="m-0">Promos for you</h6>
                    <a href="promos.html" class="ml-auto text-success">See more</a>
                </div>
                <div class="promo-slider">
                <Slider {...settings}>
        
       
                    <div class="osahan-slider-item px-2">
                        <a href="promo_details.html"><img src="assets/img/promo1.jpg" class="img-fluid mx-auto rounded" alt="Responsive image"/></a>
                    </div>
                    <div class="osahan-slider-item px-2">
                        <a href="promo_details.html"><img src="assets/img/promo2.jpg" class="img-fluid mx-auto rounded" alt="Responsive image"/></a>
                    </div>
                    <div class="osahan-slider-item px-2">
                        <a href="promo_details.html"><img src="assets/img/promo3.jpg" class="img-fluid mx-auto rounded" alt="Responsive image"/></a>
                    </div>
                    </Slider>
                </div>
            </div>

            <div class="title d-flex align-items-center mb-3 mt-3 px-3">
                <h6 class="m-0">Pick's Today</h6>
                <a class="ml-auto text-success" href="picks_today.html">See more</a>
            </div>

            <div class="pick_today px-3">
                <div class="row">
                    <div class="col-6 pr-2">
                        <div class="list-card bg-white h-100 rounded overflow-hidden position-relative shadow-sm">
                            <div class="list-card-image">
                                <a href="/Productdetail" class="text-dark">
                                    <div class="member-plan position-absolute"><span class="badge m-3 badge-danger">10%</span></div>
                                    <div class="p-3">
                                        <img src="assets/img/listing/v1.jpg" class="img-fluid item-img w-100 mb-3"/>
                                        <h6>Chilli</h6>
                                        <div class="d-flex align-items-center">
                                            <h6 class="price m-0 text-success">&#8377; 0.8/kg</h6>
                                            <a href="/Cart" class="btn btn-success btn-sm ml-auto">+</a>
                                        </div>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="col-6 pl-2">
                        <div class="list-card bg-white h-100 rounded overflow-hidden position-relative shadow-sm">
                            <div class="list-card-image">
                                <a href="/Productdetail" class="text-dark">
                                    <div class="member-plan position-absolute"><span class="badge m-3 badge-danger">5%</span></div>
                                    <div class="p-3">
                                        <img src="assets/img/listing/v2.jpg" class="img-fluid item-img w-100 mb-3"/>
                                        <h6>Onion</h6>
                                        <div class="d-flex align-items-center">
                                            <h6 class="price m-0 text-success">&#8377; 1.8/kg</h6>
                                            <a href="/Cart" class="btn btn-success btn-sm ml-auto">+</a>
                                        </div>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row pt-3">
                    <div class="col-6 pr-2">
                        <div class="list-card bg-white h-100 rounded overflow-hidden position-relative shadow-sm">
                            <div class="list-card-image">
                                <a href="/Productdetail" class="text-dark">
                                    <div class="member-plan position-absolute"><span class="badge m-3 badge-warning">5%</span></div>
                                    <div class="p-3">
                                        <img src="assets/img/listing/v3.jpg" class="img-fluid item-img w-100 mb-3"/>
                                        <h6>Tomato</h6>
                                        <div class="d-flex align-items-center">
                                            <h6 class="price m-0 text-success">&#8377; 1/kg</h6>
                                            <a class="ml-auto" href="/Cart">
                                                <div class="input-group input-spinner ml-auto cart-items-number">
                                                    <div class="input-group-prepend">
                                                        <button class="btn btn-success btn-sm" type="button" id="button-plus"> + </button>
                                                    </div>
                                                    <input type="text" class="form-control" value="1"/>
                                                    <div class="input-group-append">
                                                        <button class="btn btn-success btn-sm" type="button" id="button-minus"> − </button>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="col-6 pl-2">
                        <div class="list-card bg-white h-100 rounded overflow-hidden position-relative shadow-sm">
                            <div class="list-card-image">
                                <a href="/Productdetail" class="text-dark">
                                    <div class="member-plan position-absolute"><span class="badge m-3 badge-warning">15%</span></div>
                                    <div class="p-3">
                                        <img src="assets/img/listing/v4.jpg" class="img-fluid item-img w-100 mb-3"/>
                                        <h6>Cabbage</h6>
                                        <div class="d-flex align-items-center">
                                            <h6 class="price m-0 text-success">&#8377; 0.8/kg</h6>
                                            <a href="/Cart" class="btn btn-success btn-sm ml-auto">+</a>
                                        </div>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row pt-3">
                    <div class="col-6 pr-2">
                        <div class="list-card bg-white h-100 rounded overflow-hidden position-relative shadow-sm">
                            <div class="list-card-image">
                                <a href="/Productdetail" class="text-dark">
                                    <div class="member-plan position-absolute"><span class="badge m-3 badge-success">10%</span></div>
                                    <div class="p-3">
                                        <img src="assets/img/listing/v5.jpg" class="img-fluid item-img w-100 mb-3"/>
                                        <h6>Cauliflower</h6>
                                        <div class="d-flex align-items-center">
                                            <h6 class="price m-0 text-success">&#8377; 1.8/kg</h6>
                                            <a href="/Cart" class="btn btn-success btn-sm ml-auto">+</a>
                                        </div>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="col-6 pl-2">
                        <div class="list-card bg-white h-100 rounded overflow-hidden position-relative shadow-sm">
                            <div class="list-card-image">
                                <a href="/Productdetail" class="text-dark">
                                    <div class="member-plan position-absolute"><span class="badge m-3 badge-success">10%</span></div>
                                    <div class="p-3">
                                        <img src="assets/img/listing/v6.jpg" class="img-fluid item-img w-100 mb-3"/>
                                        <h6>Carrot</h6>
                                        <div class="d-flex align-items-center">
                                            <h6 class="price m-0 text-success">&#8377; 0.8/kg</h6>
                                            <a href="/Cart" class="btn btn-success btn-sm ml-auto">+</a>
                                        </div>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="title d-flex align-items-center p-3">
                <h6 class="m-0">Recommend for You</h6>
                <a class="ml-auto text-success" href="recommend.html">26 more</a>
            </div>

            <div class="osahan-recommend px-3">
                <div class="row">
                    <div class="col-12 mb-3">
                        <a href="/Productdetail" class="text-dark text-decoration-none">
                            <div class="list-card bg-white h-100 rounded overflow-hidden position-relative shadow-sm">
                                <div class="recommend-slider rounded pt-2">
                                <Slider {...settings}>
                                    <div class="osahan-slider-item p-2 rounded">
                                        <img src="assets/img/recommend/r1.jpg" class="img-fluid mx-auto rounded shadow-sm" alt="Responsive image"/>
                                    </div>
                                    <div class="osahan-slider-item p-2 rounded">
                                       <img src="assets/img/recommend/r2.jpg" class="img-fluid mx-auto rounded shadow-sm" alt="Responsive image"/>
                                   </div>
                                   <div class="osahan-slider-item p-2 rounded">
                                    <img src="assets/img/recommend/r3.jpg" class="img-fluid mx-auto rounded shadow-sm" alt="Responsive image"/>
                                </div>
                                </Slider>
                            </div>
                            <div class="p-3 position-relative">
                                <h6 class="mb-1 font-weight-bold text-success">Fresh Orange
                                </h6>
                                <p class="text-muted">Orange Great Quality item from Jamaica.</p>
                                <div class="d-flex align-items-center">
                                    <h6 class="m-0">&#8377; 8.8/kg</h6>
                                    <a class="ml-auto" href="/Cart">
                                        <div class="input-group input-spinner ml-auto cart-items-number">
                                            <div class="input-group-prepend">
                                                <button class="btn btn-success btn-sm" type="button" id="button-plus"> + </button>
                                            </div>
                                            <input type="text" class="form-control" value="1"/>
                                            <div class="input-group-append">
                                                <button class="btn btn-success btn-sm" type="button" id="button-minus"> − </button>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </a>
                </div>
                <div class="col-12 mb-3">
                    <a href="/Productdetail" class="text-dark text-decoration-none">
                        <div class="list-card bg-white h-100 rounded overflow-hidden position-relative shadow-sm">
                            <div class="recommend-slider rounded pt-2">
                            <Slider {...settings}>
                                <div class="osahan-slider-item p-2 ">
                                    <img src="assets/img/recommend/r4.jpg" class="img-fluid mx-auto rounded shadow-sm" alt="Responsive image"/>
                                </div>
                                <div class="osahan-slider-item p-2 ">
                                    <img src="assets/img/recommend/r5.jpg" class="img-fluid mx-auto rounded shadow-sm" alt="Responsive image"/>
                                </div>
                                <div class="osahan-slider-item p-2 ">
                                    <img src="assets/img/recommend/r6.jpg" class="img-fluid mx-auto rounded shadow-sm" alt="Responsive image"/>
                                </div>
                                </Slider>
                            </div>
                            <div class="p-3 position-relative">
                                <h6 class="mb-1 font-weight-bold text-success">Green Apple</h6>
                                <p class="text-muted">Green Apple Premium item from Vietnam.</p>
                                <div class="d-flex align-items-center">
                                    <h6 class="m-0">&#8377; 10.8/kg</h6>
                                    <a class="ml-auto" href="/Cart">
                                        <div class="input-group input-spinner ml-auto cart-items-number">
                                            <div class="input-group-prepend">
                                                <button class="btn btn-success btn-sm" type="button" id="button-plus"> + </button>
                                            </div>
                                            <input type="text" class="form-control" value="1"/>
                                            <div class="input-group-append">
                                                <button class="btn btn-success btn-sm" type="button" id="button-minus"> − </button>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </a>
                </div>
                <div class="col-12 mb-3">
                    <a href="/Productdetail" class="text-dark text-decoration-none">
                        <div class="list-card bg-white h-100 rounded overflow-hidden position-relative shadow-sm">
                            <div class="recommend-slider rounded pt-2">
                            <Slider {...settings}>
                                <div class="osahan-slider-item p-2 ">
                                    <img src="assets/img/recommend/r7.jpg" class="img-fluid mx-auto rounded shadow-sm" alt="Responsive image"/>
                                </div>
                                <div class="osahan-slider-item p-2 ">
                                    <img src="assets/img/recommend/r8.jpg" class="img-fluid mx-auto rounded shadow-sm" alt="Responsive image"/>
                                </div>
                                <div class="osahan-slider-item p-2 ">
                                    <img src="assets/img/recommend/r9.jpg" class="img-fluid mx-auto rounded shadow-sm" alt="Responsive image"/>
                                </div>
                                </Slider>
                            </div>
                            <div class="p-3 position-relative">
                                <h6 class="mb-1 font-weight-bold text-success">Fresh Apple
                                </h6>
                                <p class="text-muted">Fresh Apple Premium item from Thailand.</p>
                                <div class="d-flex align-items-center">
                                    <h6 class="m-0">&#8377; 12.8/kg</h6>
                                    <a class="ml-auto" href="/Cart">
                                        <div class="input-group input-spinner ml-auto cart-items-number">
                                            <div class="input-group-prepend">
                                                <button class="btn btn-success btn-sm" type="button" id="button-plus"> + </button>
                                            </div>
                                            <input type="text" class="form-control" value="1"/>
                                            <div class="input-group-append">
                                                <button class="btn btn-success btn-sm" type="button" id="button-minus"> − </button>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>
      </main>

          
< Footer></Footer>
    </div>
     
    );
  }
}

export default App;
