import React from "react";
import Footer from "./Footer";
import Header from "./Header";
import Sidebar from "./Sidebar";

class Orders extends React.Component {
  render() {
    return (
      <div>
        <Header></Header>
        <section class="section-padding account-bg">
          <div class="container-fluid">
            <div class="row">
              <div class="col-md-3">
                <Sidebar></Sidebar>
                     </div>
              <div class="col-md-9">
                <div class="account-main">
                    <div class="orders">
                    <div class="orders-box">
                  <div class="row">
                    <div class="col-md-12">
                      <div class="order-title">
                       <div class="order-list">
                            <p>Order No - <span>1372346324572</span></p>
                            <p>Order Status -  <span>Delivered</span></p>
                            <p>Order Total -    <span> ₹3500</span></p>
                       </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12">
                      <div class="cart-body">
                        <div class="row">
                          <div class="col-md-2">
                            <div class="cart-img">
                              <img src="http://admin.greensworthnuts.com/greensworthnuts/images/ProductImages/1.jpg"></img>
                            </div>
                          </div>
                          <div class="col-md-5">
                            <div class="cart-product-title">
                              <h5>
                              AUSTRALIA CARMEL ALMONDS-250 gm

                              </h5>
                              <p class="quantity-text">Quantity - 1</p>
                              <h6 class="warranty-text">
                                {" "}
                                Nuts & Dry Fruits

                              </h6>
                            </div>
                          </div>
                          <div class="col-md-3">
                         
                            <div class="price-box">
                              <h6 class="warranty-text">
                                {" "}
                                ₹3500
                              </h6>
                              <span>
                                <s>
                                  {" "}
                                  ₹6,997
                                </s>
                              </span>
                            </div>
                          </div>
                          <div class="col-md-2">
                           <button class="btn btn-common">
                              Order Again
                           </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
         
                    </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer></Footer>
      </div>
    );
  }
}

export default Orders;
