import React from 'react'

class Footer extends React.Component {
    render () {
      return (
          <div>
              <div class="osahan-menu-fotter fixed-bottom bg-white text-center border-top">
    <div class="row m-0">
        <a href="./" class="text-dark small col font-weight-bold text-decoration-none p-2 selected">
            <p class="h5 m-0"><i class="text-success icofont-grocery"></i></p>
            Shop
        </a>
        <a href="./cart" class="text-muted col small text-decoration-none p-2">
            <p class="h5 m-0"><i class="icofont-cart"></i></p>
            Cart
        </a>
        <a href="./completeorder" class="text-muted col small text-decoration-none p-2">
            <p class="h5 m-0"><i class="icofont-bag"></i></p>
            My Order
        </a>
        <a href="./account" class="text-muted small col text-decoration-none p-2">
            <p class="h5 m-0"><i class="icofont-user"></i></p>
            Account
        </a>
    </div>
</div>    



          </div>
      )
    }
  }

  export default Footer;