import React from 'react';


class Sidebar extends React.Component {
    render () {
      return (
      <div>
           <div class="side-bar">
                    <ul class="account-list">
                      <li class="active">
                        <h5>Account Dashboard</h5>
                        <p>Get An Overview Of Your Account</p>
                      </li>
                      <li>
                        <a href="/orders"><h5>View My Orders</h5></a>
                        <p>Check Shipping Status, Reorder Items</p>
                      </li>
                      <li>
                      <a href="/wishlist"><h5>My Wishlist</h5></a>
                        <p>Add and Remove Items to Cart</p>
                      </li>
                      <li>
                      <a href="/profile"><h5>My Profile</h5></a>
                        <p>Your Name, Mobile, Email</p>
                      </li>
                      <li>
                      <a href="/editaddress"> <h5>My Address</h5></a>
                        <p>Add and Edit Addresses</p>
                      </li>
                      <li>
                      <a href="/changepassword"> <h5>Change Password</h5></a>
                        <p>Change and Update your Password</p>
                      </li>
                      <li>
                      <a href="/"><h5>Logout</h5></a>
                      </li>
                    </ul>
                </div>
        
      </div>
      )
    }
  }

  export default Sidebar;