import React from "react";
import Header from "./Header";
import Slider from "react-slick";

import PostApiCall from './Api';
import moment from 'moment';
import Notiflix from "notiflix-react";

import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import Footer from "./Footer";

class Cart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedCategories: "",
      ProductCart: [],

      open: true,
      SearchText: ''
    };
  }

  componentDidMount() {

    if (localStorage.getItem("CustomerData")) {
      Notiflix.Loading.Dots('Please wait...');
      var login = localStorage.getItem('CustomerData');
      var logindetails = JSON.parse(login)

      // console.log('cart')
      PostApiCall.postRequest({
        id: logindetails[0].fld_customerid,
      }, 'GetCartWebsite')
        .then(res => res.json())
        .then(res1 => {

          if (res1.data) {
            Notiflix.Loading.Remove()
            console.log(res1.data)

            this.setState({ ProductCart: res1.data })
          }
        })


    }
  }

  render() {
    
    return (
      <div>
        <Header></Header>
        
        <div class="osahan-cart">
        <div class="p-3 border-bottom">
            <div class="d-flex align-items-center">
                <h5 class="font-weight-bold m-0">Cart</h5>
                <a class="toggle ml-auto" href="#"><i class="icofont-navigation-menu"></i></a>
            </div>
        </div>
        <div class="osahan-body">
            <div class="cart-items bg-white position-relative border-bottom">
                <a href="product_details.html" class="position-absolute">
                    <span class="badge badge-danger m-3">10%</span>
                </a>
                <div class="d-flex  align-items-center p-3">
                    <a href="product_details.html"><img src="assets/img/cart/g1.png" class="img-fluid"/></a>
                    <a href="product_details.html" class="ml-3 text-dark text-decoration-none w-100">
                        <h5 class="mb-1">Bread</h5>
                        <p class="text-muted mb-2"><del class="text-success mr-1">&#8377; 1.20kg</del> &#8377; 0.98/kg</p>
                        <div class="d-flex align-items-center">
                            <p class="total_price font-weight-bold m-0">&#8377; 2.82</p>
                            <div class="input-group input-spinner ml-auto cart-items-number">
                                <div class="input-group-prepend">
                                    <button class="btn btn-success btn-sm" type="button" id="button-plus"> + </button>
                                </div>
                                <input type="text" class="form-control" value="1"/>
                                <div class="input-group-append">
                                    <button class="btn btn-success btn-sm" type="button" id="button-minus"> − </button>
                                </div>
                            </div>
                        </div>
                    </a>
                </div>
            </div>
            <div class="cart-items bg-white position-relative border-bottom">
                <div class="d-flex  align-items-center p-3">
                    <a href="product_details.html"><img src="assets/img/cart/g2.png" class="img-fluid"/></a>
                    <a href="product_details.html" class="ml-3 text-dark text-decoration-none w-100">
                        <h5 class="mb-1">Spinach</h5>
                        <p class="text-muted mb-2"><del class="text-success mr-1">&#8377; 1.20kg</del> &#8377; 0.98/kg</p>
                        <div class="d-flex align-items-center">
                            <p class="total_price font-weight-bold m-0">&#8377; 3.82</p>
                            <div class="input-group input-spinner ml-auto cart-items-number">
                                <div class="input-group-prepend">
                                    <button class="btn btn-success btn-sm" type="button" id="button-plus"> + </button>
                                </div>
                                <input type="text" class="form-control" value="3"/>
                                <div class="input-group-append">
                                    <button class="btn btn-success btn-sm" type="button" id="button-minus"> − </button>
                                </div>
                            </div>
                        </div>
                    </a>
                </div>
            </div>
            <div class="cart-items bg-white position-relative border-bottom">
                <div class="d-flex  align-items-center p-3">
                    <a href="product_details.html"><img src="assets/img/cart/g3.png" class="img-fluid"/></a>
                    <a href="product_details.html" class="ml-3 text-dark text-decoration-none w-100">
                        <h5 class="mb-1">Chilli</h5>
                        <p class="text-muted mb-2"><del class="text-success mr-1">&#8377; 1.20kg</del> &#8377; 0.98/kg</p>
                        <div class="d-flex align-items-center">
                            <p class="total_price font-weight-bold m-0">&#8377; 2.82</p>
                            <div class="input-group input-spinner ml-auto cart-items-number">
                                <div class="input-group-prepend">
                                    <button class="btn btn-success btn-sm" type="button" id="button-plus"> + </button>
                                </div>
                                <input type="text" class="form-control" value="2"/>
                                <div class="input-group-append">
                                    <button class="btn btn-success btn-sm" type="button" id="button-minus"> − </button>
                                </div>
                            </div>
                        </div>
                    </a>
                </div>
            </div>
            <div class="p-3 mt-5">
                <a href="/orderaddress" class="text-decoration-none">
                    <div class="rounded shadow bg-success d-flex align-items-center p-3 text-white">
                        <div class="more">
                            <h6 class="m-0">Subtotal &#8377; 8.52</h6>
                            <p class="small m-0">Proceed to checkout</p>
                        </div>
                        <div class="ml-auto"><i class="icofont-simple-right"></i></div>
                    </div>
                </a>
            </div>
        </div>
        </div>
        <Footer></Footer>
      </div>
    );
  }
}

export default Cart;
