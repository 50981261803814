import React from 'react'
import Header from "./Header";

class checkout extends React.Component {
    render () {
      return (
          <div>
              <Header></Header>
              <div class="osahan-checkout">
                <div class="p-3 border-bottom">
                    <div class="d-flex align-items-center">
                        <a class="font-weight-bold text-success text-decoration-none" href="/orderaddress">
                            <i class="icofont-rounded-left back-page"></i></a>
                            <h6 class="font-weight-bold m-0 ml-3">Checkout</h6>
                            <a class="toggle ml-auto" href="#"><i class="icofont-navigation-menu"></i></a>
                        </div>
                </div>
            </div>

        <div class="address p-3 bg-white">
            <h6 class="m-0 text-dark d-flex align-items-center">Address <span class="small ml-auto"><a href="/orderaddress" class="font-weight-bold text-decoration-none text-success"><i class="icofont-location-arrow"></i> Change</a></span></h6>
        </div>

        <div class="p-3">
            <div class="d-flex align-items-center">
                <p class="mb-2 font-weight-bold">Home</p>
                <p class="mb-2 badge badge-success ml-auto">Default</p>
            </div>
            <p class="small text-muted m-0">1001 Veterans Blvd</p>
            <p class="small text-muted m-0">Redwood City, CA 94063</p>
        </div>
        <div class="address p-3 bg-white">
            <h6 class="m-0 text-dark">Payment Method</h6>
        </div>
        <div class="p-3">
            <a href="order_payment.html" class="text-success text-decoration-none w-100">
                <div class="d-flex align-items-center">
                    <i class="icofont-credit-card"></i> <span class="ml-3">Add Payment Method</span> <i class="icofont-rounded-right ml-auto"></i>
                </div>
            </a>
        </div>
        <div class="address p-3 bg-white">
            <h6 class="text-dark m-0">Promo Code</h6>
        </div>

        <div>
            <div class="accordion" id="accordionExample">
                <div class="d-flex align-items-center" id="headingThree">
                    <a class="p-3 d-flex align-items-center text-decoration-none text-success w-100" type="button" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                        <i class="icofont-badge mr-3"></i> Add Promo Code
                        <i class="icofont-rounded-down ml-auto"></i>
                    </a>
                </div>
                <div id="collapseThree" class="collapse p-3 border-top" aria-labelledby="headingThree" data-parent="#accordionExample">
                    <form class="row">
                        <div class="col-8 m-0 pr-1">
                            <input type="text" class="form-control" id="exampleInputPromo1" placeholder="Enter Promo Code" required/>
                        </div>
                        <div class="col-4 pl-1">
                            <button type="submit" class="btn btn-success btn-block">Submit</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <div class="address p-3 bg-white">
            <h6 class="text-dark m-0">Total</h6>
        </div>
        <div class="p-3">
            <div class="clearfix">
                <p class="mb-1 text-muted">Item Total <span class="float-right text-dark">&#8377; 3140</span></p>
                <p class="mb-1 text-muted">Restaurant Charges <span class="float-right text-dark">&#8377; 62.8</span></p>
                <p class="mb-1 text-muted">Delivery Fee<span class="text-info ml-1"><i class="icofont-info-circle"></i></span><span class="float-right text-dark">&#8377; 10</span></p>
                <p class="mb-1 text-success">Total Discount<span class="float-right text-success">&#8377; 1884</span></p>
                <hr></hr>
                <h6 class="font-weight-bold mb-0">TO PAY <span class="float-right">&#8377; 1329</span></h6>
            </div>
        </div>
        <div class="fixed-bottom">
            <a href="#" class="btn btn-success btn-block">Place Order</a>
        </div>

          </div>
      )
    }
  }

  export default checkout;