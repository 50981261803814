import React from 'react'

class Search extends React.Component {
    render () {
      return (
          <div>
        <div class="osahan-search">
        <div class="p-3 border-bottom">
            <div class="d-flex align-items-center">
                <a class="font-weight-bold text-success text-decoration-none" href="./">
                    <i class="icofont-rounded-left back-page"></i></a>
                    <div class="input-group ml-3 rounded shadow-sm overflow-hidden bg-white">
                        <div class="input-group-prepend">
                            <button class="border-0 btn btn-outline-secondary text-success bg-white"><i class="icofont-search"></i></button>
                        </div>
                        <input type="text" class="shadow-none border-0 form-control pl-0" placeholder="Search for Products.."  value="Apple" />
                    </div>
                </div>
            </div>
        </div>
        <a href="/Productslists" class="text-dark">
            <div class="d-flex align-items-center border-bottom p-3">
                <img src="assets/img/search/s1.jpg" class="img-fluid rounded shadow-sm mr-3"/>
                <span class="font-weight-bold">
                    Japan Green Apple
                    <p class="small text-muted m-0">High quality Apple fresh from Japan.</p>
                </span>
            </div>
        </a>
        <a href="/Productslists" class="text-dark">
            <div class="d-flex align-items-center border-bottom p-3">
                <img src="assets/img/search/s2.jpg" class="img-fluid rounded shadow-sm mr-3"/>
                <span class="font-weight-bold">
                    Japan Red Apple
                    <p class="small text-muted m-0">Fresh Apple Premium item from Thailand.</p>
                </span>
            </div>
        </a>
        <a href="/Productslists" class="text-dark">
            <div class="d-flex align-items-center border-bottom p-3">
                <img src="assets/img/search/s3.jpg" class="img-fluid rounded shadow-sm mr-3"/>
                <span class="font-weight-bold">
                    Fresh Orange
                    <p class="small text-muted m-0">Orange Great Quality item from Jamaica.</p>
                </span>
            </div>
        </a>
        <a href="/Productslists" class="text-dark">
            <div class="d-flex align-items-center border-bottom p-3">
                <img src="assets/img/search/s4.jpg" class="img-fluid rounded shadow-sm mr-3"/>
                <span class="font-weight-bold">
                    Fresh Tomato
                    <p class="small text-muted m-0">High quality Tomato from South Korea.</p>
                </span>
            </div>
        </a>   



          </div>
      )
    }
  }

  export default Search;