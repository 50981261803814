import React from 'react'
import Header from "./Header";
import Footer from "./Footer";
class Completeorder extends React.Component {
    render () {
      return (
          <div>
       <Header></Header>
       <div class="osahan-order">
        <div class="order-menu">
            <h5 class="font-weight-bold p-3 d-flex align-items-center">My Order <a class="toggle ml-auto" href="#"><i class="icofont-navigation-menu"></i></a></h5>
            <div class="row m-0 text-center">
                <div class="col pb-2 border-success border-bottom">
                    <a href="#" class="text-success font-weight-bold text-decoration-none">Completed</a>
                </div>
                <div class="col pb-2 border-bottom">
                    <a href="#" class="text-muted text-decoration-none">On Progress</a>
                </div>
                <div class="col pb-2 border-bottom">
                    <a href="#" class="text-muted text-decoration-none">Canceled</a>
                </div>
            </div>
        </div>
        <div class="order-body px-3 pt-3">
            <div class="pb-3">
                <a href="#" class="text-decoration-none text-dark">
                    <div class="p-3 rounded shadow-sm bg-white">
                        <div class="d-flex align-items-center mb-3">
                            <p class="bg-success text-white py-1 px-2 mb-0 rounded small">Delivered</p>
                            <p class="text-muted ml-auto small mb-0"><i class="icofont-clock-time"></i> 06/04/2020</p>
                        </div>
                        <div class="d-flex">
                            <p class="text-muted m-0">Transaction. ID<br></br>
                                <span class="text-dark font-weight-bold">321DERS</span>
                            </p>
                            <p class="text-muted m-0 ml-auto">Delivered to<br></br>
                                <span class="text-dark font-weight-bold">Home</span>
                            </p>
                            <p class="text-muted m-0 ml-auto">Total Payment<br></br>
                                <span class="text-dark font-weight-bold">$12.74</span>
                            </p>
                        </div>
                    </div>
                </a>
            </div>
            <div class="pb-3">
                <a href="#" class="text-decoration-none text-dark">
                    <div class="p-3 rounded shadow-sm bg-white">
                        <div class="d-flex align-items-center mb-3">
                            <p class="bg-success text-white py-1 px-2 rounded small m-0">Delivered</p>
                            <p class="text-muted ml-auto small m-0"><i class="icofont-clock-time"></i> 06/04/2020</p>
                        </div>
                        <div class="d-flex">
                            <p class="text-muted m-0">Transaction. ID<br></br>
                                <span class="text-dark font-weight-bold">321DERS</span>
                            </p>
                            <p class="text-muted m-0 ml-auto">Delivered to<br></br>
                                <span class="text-dark font-weight-bold">Home</span>
                            </p>
                            <p class="text-muted m-0 ml-auto">Total Payment<br></br>
                                <span class="text-dark font-weight-bold">$12.74</span>
                            </p>
                        </div>
                    </div>
                </a>
            </div>
            <div class="pb-3">
                <a href="#" class="text-decoration-none text-dark">
                    <div class="p-3 rounded shadow-sm bg-white">
                        <div class="d-flex align-items-center mb-3">
                            <p class="bg-success text-white py-1 px-2 rounded small mb-0">Delivered</p>
                            <p class="text-muted ml-auto small m-0"><i class="icofont-clock-time"></i> 06/04/2020</p>
                        </div>
                        <div class="d-flex">
                            <p class="text-muted m-0">Transaction. ID<br></br>
                                <span class="text-dark font-weight-bold">#321DERS</span>
                            </p>
                            <p class="text-muted m-0 ml-auto">Delivered to<br></br>
                                <span class="text-dark font-weight-bold">Home</span>
                            </p>
                            <p class="text-muted m-0 ml-auto">Total Payment<br></br>
                                <span class="text-dark font-weight-bold">$12.74</span>
                            </p>
                        </div>
                    </div>
                </a>
            </div>
            <div class="pb-3">
                <a href="#" class="text-decoration-none text-dark">
                    <div class="p-3 rounded shadow-sm bg-white">
                        <div class="d-flex align-items-center mb-3">
                            <p class="bg-warning text-white py-1 px-2 rounded small m-0">On Process</p>
                            <p class="text-muted ml-auto small m-0"><i class="icofont-clock-time"></i> 06/04/2020</p>
                        </div>
                        <div class="d-flex">
                            <p class="text-muted m-0">Transaction. ID<br></br>
                                <span class="text-dark font-weight-bold">#321DERS</span>
                            </p>
                            <p class="text-muted m-0 ml-auto">Delivered to<br></br>
                                <span class="text-dark font-weight-bold">Home</span>
                            </p>
                            <p class="text-muted m-0 ml-auto">Total Payment<br></br>
                                <span class="text-dark font-weight-bold">$12.74</span>
                            </p>
                        </div>
                    </div>
                </a>
            </div>
            <div class="pb-3">
                <a href="#" class="text-decoration-none text-dark">
                    <div class="p-3 rounded shadow-sm bg-white">
                        <div class="d-flex align-items-center mb-3">
                            <p class="bg-danger text-white py-1 px-2 rounded small m-0">Canceled</p>
                            <p class="text-muted ml-auto small m-0"><i class="icofont-clock-time"></i> 06/04/2020</p>
                        </div>
                        <div class="d-flex">
                            <p class="text-muted m-0">Transaction. ID<br></br>
                                <span class="text-dark font-weight-bold">#321DERS</span>
                            </p>
                            <p class="text-muted m-0 ml-auto">Delivered to<br></br>
                                <span class="text-dark font-weight-bold">Home</span>
                            </p>
                            <p class="text-muted m-0 ml-auto">Total Payment<br></br>
                                <span class="text-dark font-weight-bold">$12.74</span>
                            </p>
                        </div>
                    </div>
                </a>
            </div>
        </div>
    </div>

<Footer></Footer>
          </div>
      )
    }
  }

  export default Completeorder;