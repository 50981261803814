import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { createBrowserHistory } from "history";
import { BrowserRouter as Router, Route } from "react-router-dom";
import Productslists from './Productslists';
import Productdetail from './Productdetail';
import Cart from './Cart';
import Checkout from './Checkout';
import Account from './Account';
import Orders from './Orders';
import Wishlist from './Wishlist';
import Profile from './Profile';
import Editaddress from './Editaddress';
import Changepassword from './Changepassword';
import Login from './Login';
import Register from './Register';
import Search from './Search';
import Orderaddress from './Orderaddress';
import Completeorder from './Completeorder';
const history = createBrowserHistory();

ReactDOM.render(
  <Router history={history}>
  <Route exact path={`${process.env.PUBLIC_URL}/`} component={App}></Route>
  

  <Route exact path={`${process.env.PUBLIC_URL}/productslists`} component={Productslists}></Route>
  <Route exact path={`${process.env.PUBLIC_URL}/productdetail`} component={Productdetail}></Route>
  <Route exact path={`${process.env.PUBLIC_URL}/cart`} component={Cart}></Route>
  <Route exact path={`${process.env.PUBLIC_URL}/checkout`} component={Checkout}></Route>
  <Route exact path={`${process.env.PUBLIC_URL}/account`} component={Account}></Route>
  <Route exact path={`${process.env.PUBLIC_URL}/orders`} component={Orders}></Route>
  <Route exact path={`${process.env.PUBLIC_URL}/wishlist`} component={Wishlist}></Route>
  <Route exact path={`${process.env.PUBLIC_URL}/profile`} component={Profile}></Route>
  <Route exact path={`${process.env.PUBLIC_URL}/editaddress`} component={Editaddress}></Route>
  <Route exact path={`${process.env.PUBLIC_URL}/changepassword`} component={Changepassword}></Route>

  <Route exact path={`${process.env.PUBLIC_URL}/login`} component={Login}></Route>
  <Route exact path={`${process.env.PUBLIC_URL}/orderaddress`} component={Orderaddress}></Route>
  <Route exact path={`${process.env.PUBLIC_URL}/completeorder`} component={Completeorder}></Route>
  <Route exact path={`${process.env.PUBLIC_URL}/search`} component={Search}></Route>

  <Route exact path={`${process.env.PUBLIC_URL}/register`} component={Register}></Route>




</Router>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
