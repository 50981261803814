import React from "react";
import Footer from "./Footer";
import Header from "./Header";

import GetApiCall from "./GetApi";
import moment from 'moment';

import Notiflix from "notiflix-react";
import PostApiCall from "./Api"

class Register extends React.Component {
   

    render() {
        return (
            <div>
                <Header></Header>
                <div class="osahan-signup">
        <div class="border-bottom p-3 d-flex align-items-center">
            <a class="font-weight-bold text-success text-decoration-none" href="account-setup.html"><i class="icofont-rounded-left back-page"></i></a>
            <a class="toggle ml-auto" href="#"><i class="icofont-navigation-menu"></i></a>
        </div>
        <div class="p-3">
            <h2 class="my-0">Let's get started</h2>
            <p>Create account to see our top picks for you!</p>
            <form action="/login">
                <div class="form-group">
                    <label for="exampleInputName1">Name</label>
                    <input placeholder="Enter Name" type="text" class="form-control" id="exampleInputName1" aria-describedby="emailHelp"/>
                </div>
                <div class="form-group">
                    <label for="exampleInputNumber1">Phone Number</label>
                    <input placeholder="Enter Phone Number" type="number" class="form-control" id="exampleInputNumber1" aria-describedby="emailHelp"/>
                </div>
                <div class="form-group">
                    <label for="exampleInputEmail1">Email</label>
                    <input placeholder="Enter Email" type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp"/>
                </div>
                <div class="form-group">
                    <label for="exampleInputPassword1">Password</label>
                    <input placeholder="Enter Password" type="password" class="form-control" id="exampleInputPassword1"/>
                </div>
                <div class="form-group">
                    <label for="exampleInputPassword2">Confirmation Password</label>
                    <input placeholder="Enter Confirmation Password" type="password" class="form-control" id="exampleInputPassword2"/>
                </div>
                <button type="submit" class="btn btn-success rounded btn-lg btn-block">Create Account</button>
            </form>
            <p class="text-muted text-center small py-2 m-0">or</p>
            <a href="./" class="btn btn-dark btn-block rounded btn-lg btn-apple">
                <i class="icofont-brand-apple mr-2"></i> Sign up with Apple
            </a>
            <a href="./" class="btn btn-light btn-block rounded btn-lg btn-google">
                <i class="icofont-google-plus text-danger mr-2"></i> Sign up with Google
            </a>
        </div>
    </div>

    <div class="osahan-fotter fixed-bottom">
        <a href="/login" class="btn btn-block btn-lg bg-white">Don't have an account? Sign in</a>
    </div>
            </div>

        )
    }
}

export default Register;