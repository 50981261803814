import React from "react";
import Footer from "./Footer";
import Header from "./Header";
import Sidebar from "./Sidebar";


class Profile extends React.Component {
    render () {
      return (
        <div>
        <Header></Header>
        <div class="osahan-profle">
        <div class="p-3 border-bottom">
            <div class="d-flex align-items-center">
                <a class="font-weight-bold text-success text-decoration-none" href="my_account.html">
                    <i class="icofont-rounded-left back-page"></i></a>
                    <h6 class="font-weight-bold m-0 ml-3">Edit Profile</h6>
                    <a class="toggle ml-auto" href="#"><i class="icofont-navigation-menu"></i></a>
                </div>
            </div>
        </div>

        <div id="edit_profile">
            <div class="p-4 profile text-center border-bottom">
                <img src="assets/img/user.jpg" class="img-fluid rounded-pill"/>
                <h6 class="font-weight-bold m-0 mt-2">Manjeet kumar</h6>
                <p class="small text-muted m-0"><a href="#">user@website.com</a></p>
            </div>
            <div class="p-3">
                <form action="#">
                    <div class="form-group">
                        <label for="exampleInputName1">Full Name</label>
                        <input type="text" class="form-control" id="exampleInputName1" value="Manjeet kumar"/>
                    </div>
                    <div class="form-group">
                        <label for="exampleInputNumber1">Mobile Number</label>
                        <input type="number" class="form-control" id="exampleInputNumber1" value="1234567890"/>
                    </div>
                    <div class="form-group">
                        <label for="exampleInputEmail1">Email</label>
                        <input type="email" class="form-control" id="exampleInputEmail1" value="user@website.com"/>
                    </div>
                    <div class="text-center">
                        <button type="submit" class="btn btn-success btn-block btn-lg">Save Changes</button>
                    </div>
                </form>
            </div>
            <div class="additional">
                <div class="change_password border-bottom border-top">
                    <a href="change_password.html" class="p-3 bg-white btn d-flex align-items-center">Change Password
                        <i class="icofont-rounded-right ml-auto"></i></a>
                    </div>
                    <div class="deactivate_account border-bottom">
                        <a href="deactivate_account.html" class="p-3 bg-white btn d-flex align-items-center">Deactivate Account
                            <i class="icofont-rounded-right ml-auto"></i></a>
                        </div>
                    </div>
                </div>
        <Footer></Footer>
      </div>
 
      )
    }
  }

  export default Profile;