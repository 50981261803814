import React from 'react'
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import Header from "./Header";


class Productslists extends React.Component {
    state ={
        openModal : false
    };
    onClickButton = e =>{
        e.preventDefault()
        this.setState({openModal : true})
};
onCloseModal = ()=>{
    this.setState({openModal : false})
};
   
    render () {
      return (
          <div>
       <Header></Header>
       <div class="osahan-listing">
        <div class="p-3">
            <div class="d-flex align-items-center">
                <a class="font-weight-bold text-success text-decoration-none" href="./"><i class="icofont-rounded-left back-page"></i></a><span class="font-weight-bold ml-3 h6 mb-0">Vegetables</span>
                <a class="toggle ml-auto" href="#"><i class="icofont-navigation-menu"></i></a>
            </div>
        </div>
        <div class="osahan-listing px-3 bg-white">
            <div class="row border-bottom border-top">
                <div class="col-6 p-0 border-right">
                    <div class="list-card-image">
                        <a href="/Productdetail" class="text-dark">
                            <div class="member-plan position-absolute"><span class="badge m-3 badge-danger">10%</span></div>
                            <div class="p-3">
                                <img src="assets/img/listing/v1.jpg" class="img-fluid item-img w-100 mb-3"/>
                                <h6>Chilli</h6>
                                <div class="d-flex align-items-center">
                                    <h6 class="price m-0 text-success">&#8377; 0.8/kg</h6>
                                    <a href="/cart" class="btn btn-success btn-sm ml-auto">+</a>
                                </div>
                            </div>
                        </a>
                    </div>
                </div>
                <div class="col-6 p-0">
                    <div class="list-card-image">
                        <a href="/Productdetail" class="text-dark">
                            <div class="member-plan position-absolute"><span class="badge m-3 badge-danger">5%</span></div>
                            <div class="p-3">
                                <img src="assets/img/listing/v2.jpg" class="img-fluid item-img w-100 mb-3"/>
                                <h6>Onion</h6>
                                <div class="d-flex align-items-center">
                                    <h6 class="price m-0 text-success">&#8377; 1.8/kg</h6>
                                    <a class="ml-auto" href="/cart">
                                        <div class="input-group input-spinner ml-auto cart-items-number">
                                            <div class="input-group-prepend">
                                                <button class="btn btn-success btn-sm" type="button" id="button-plus"> + </button>
                                            </div>
                                            <input type="text" class="form-control" value="1"/>
                                            <div class="input-group-append">
                                                <button class="btn btn-success btn-sm" type="button" id="button-minus"> − </button>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </a>
                    </div>
                </div>
            </div>
            <div class="row border-bottom">
                <div class="col-6 p-0 border-right">
                    <div class="list-card-image">
                        <a href="/Productdetail" class="text-dark">
                            <div class="member-plan position-absolute"><span class="badge m-3 badge-warning">5%</span></div>
                            <div class="p-3">
                                <img src="assets/img/listing/v3.jpg" class="img-fluid item-img w-100 mb-3"/>
                                <h6>Tomato</h6>
                                <div class="d-flex align-items-center">
                                    <h6 class="price m-0 text-success">&#8377; 2.5/kg</h6>
                                    <a href="/cart" class="btn btn-success btn-sm ml-auto">+</a>
                                </div>
                            </div>
                        </a>
                    </div>
                </div>
                <div class="col-6 p-0">
                    <div class="list-card-image">
                        <a href="/Productdetail" class="text-dark">
                            <div class="member-plan position-absolute"><span class="badge m-3 badge-warning">15%</span></div>
                            <div class="p-3">
                                <img src="assets/img/listing/v4.jpg" class="img-fluid item-img w-100 mb-3"/>
                                <h6>Cabbage</h6>
                                <div class="d-flex align-items-center">
                                    <h6 class="price m-0 text-success">&#8377; 0.8/kg</h6>
                                    <a href="/cart" class="btn btn-success btn-sm ml-auto">+</a>
                                </div>
                            </div>
                        </a>
                    </div>
                </div>
            </div>
            <div class="row border-bottom">
                <div class="col-6 p-0 border-right">
                    <div class="list-card-image">
                        <a href="/Productdetail" class="text-dark">
                            <div class="member-plan position-absolute"><span class="badge m-3 badge-success">10%</span></div>
                            <div class="p-3">
                                <img src="assets/img/listing/v5.jpg" class="img-fluid item-img w-100 mb-3"/>
                                <h6>Cauliflower</h6>
                                <div class="d-flex align-items-center">
                                    <h6 class="price m-0 text-success">&#8377; 1.8/kg</h6>
                                    <a class="ml-auto" href="/cart">
                                        <div class="input-group input-spinner ml-auto cart-items-number">
                                            <div class="input-group-prepend">
                                                <button class="btn btn-success btn-sm" type="button" id="button-plus"> + </button>
                                            </div>
                                            <input type="text" class="form-control" value="1"/>
                                            <div class="input-group-append">
                                                <button class="btn btn-success btn-sm" type="button" id="button-minus"> − </button>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </a>
                    </div>
                </div>
                <div class="col-6 p-0">
                    <div class="list-card-image">
                        <a href="/Productdetail" class="text-dark">
                            <div class="member-plan position-absolute"><span class="badge m-3 badge-success">10%</span></div>
                            <div class="p-3">
                                <img src="assets/img/listing/v6.jpg" class="img-fluid item-img w-100 mb-3"/>
                                <h6>Carrot</h6>
                                <div class="d-flex align-items-center">
                                    <h6 class="price m-0 text-success">&#8377; 0.8/kg</h6>
                                    <a href="/cart" class="btn btn-success btn-sm ml-auto">+</a>
                                </div>
                            </div>
                        </a>
                    </div>
                </div>
            </div>
            <div class="row border-bottom">
                <div class="col-6 p-0 border-right">
                    <div class="list-card-image">
                        <a href="/Productdetail" class="text-dark">
                            <div class="member-plan position-absolute"><span class="badge m-3 badge-warning">5%</span></div>
                            <div class="p-3">
                                <img src="assets/img/listing/v7.jpg" class="img-fluid item-img w-100 mb-3"/>
                                <h6>Star Anise</h6>
                                <div class="d-flex align-items-center">
                                    <h6 class="price m-0 text-success">&#8377; 2.5/kg</h6>
                                    <a href="/cart" class="btn btn-success btn-sm ml-auto">+</a>
                                </div>
                            </div>
                        </a>
                    </div>
                </div>
                <div class="col-6 p-0">
                    <div class="list-card-image">
                        <a href="/Productdetail" class="text-dark">
                            <div class="member-plan position-absolute"><span class="badge m-3 badge-warning">15%</span></div>
                            <div class="p-3">
                                <img src="assets/img/listing/v8.jpg" class="img-fluid item-img w-100 mb-3"/>
                                <h6>Brinjal</h6>
                                <div class="d-flex align-items-center">
                                    <h6 class="price m-0 text-success">&#8377; 0.8/kg</h6>
                                    <a href="/cart" class="btn btn-success btn-sm ml-auto">+</a>
                                </div>
                            </div>
                        </a>
                    </div>
                </div>
            </div>
            <div class="row border-bottom">
                <div class="col-6 p-0 border-right">
                    <div class="list-card-image">
                        <a href="/Productdetail" class="text-dark">
                            <div class="member-plan position-absolute"><span class="badge m-3 badge-warning">5%</span></div>
                            <div class="p-3">
                                <img src="assets/img/listing/v9.jpg" class="img-fluid item-img w-100 mb-3"/>
                                <h6>Capsicum</h6>
                                <div class="d-flex align-items-center">
                                    <h6 class="price m-0 text-success">&#8377; 2.5/kg</h6>
                                    <a href="/cart" class="btn btn-success btn-sm ml-auto">+</a>
                                </div>
                            </div>
                        </a>
                    </div>
                </div>
                <div class="col-6 p-0">
                    <div class="list-card-image">
                        <a href="/Productdetail" class="text-dark">
                            <div class="member-plan position-absolute"><span class="badge m-3 badge-warning">15%</span></div>
                            <div class="p-3">
                                <img src="assets/img/listing/v10.jpg" class="img-fluid item-img w-100 mb-3"/>
                                <h6>Lady Finger</h6>
                                <div class="d-flex align-items-center">
                                    <h6 class="price m-0 text-success">&#8377; 0.8/kg</h6>
                                    <a href="/cart" class="btn btn-success btn-sm ml-auto">+</a>
                                </div>
                            </div>
                        </a>
                    </div>
                </div>
            </div>
            <div class="row border-bottom">
                <div class="col-6 p-0 border-right">
                    <div class="list-card-image">
                        <a href="/Productdetail" class="text-dark">
                            <div class="member-plan position-absolute"><span class="badge m-3 badge-warning">5%</span></div>
                            <div class="p-3">
                                <img src="assets/img/listing/v11.jpg" class="img-fluid item-img w-100 mb-3"/>
                                <h6>Garlic</h6>
                                <div class="d-flex align-items-center">
                                    <h6 class="price m-0 text-success">&#8377; 2.5/kg</h6>
                                    <a href="/cart" class="btn btn-success btn-sm ml-auto">+</a>
                                </div>
                            </div>
                        </a>
                    </div>
                </div>
                <div class="col-6 p-0">
                    <div class="list-card-image">
                        <a href="/Productdetail" class="text-dark">
                            <div class="member-plan position-absolute"><span class="badge m-3 badge-warning">15%</span></div>
                            <div class="p-3">
                                <img src="assets/img/listing/v12.jpg" class="img-fluid item-img w-100 mb-3"/>
                                <h6>Ginger</h6>
                                <div class="d-flex align-items-center">
                                    <h6 class="price m-0 text-success">&#8377; 0.8/kg</h6>
                                    <a href="/cart" class="btn btn-success btn-sm ml-auto">+</a>
                                </div>
                            </div>
                        </a>
                    </div>
                </div>
            </div>

        </div>
    </div>

    <div class="row m-0 text-center border-bottom border-top fixed-bottom bg-white">
        <div class="col-6 p-0 border-right">
            <a href="#" onClick={this.onClickButton} class="btn text-muted"><i class="icofont-filter mr-2"></i> Filter</a>
        </div>
        <div class="col-6 p-0">
            <a href="#" onClick={this.onClickButton}  class="btn text-muted"><i class="icofont-signal mr-2"></i> Sort</a>
        </div>
    </div>

   

    <Modal open={this.state.openModal} onClose={this.onCloseModal}  class="">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Filter</h5>
                    <button type="button" class="close" onClick={this. onCloseModal}  >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body p-0">
                    <div class="osahan-filter">
                        <div class="filter">

                            <div class="p-3 bg-light border-bottom">
                                <h6 class="m-0">SORT BY</h6>
                            </div>
                            <div class="custom-control border-bottom px-0  custom-radio">
                                <input type="radio" id="customRadio1" name="location" class="custom-control-input" checked/>
                                <label class="custom-control-label py-3 w-100 px-3" for="customRadio1">Top Rated</label>
                            </div>
                            <div class="custom-control border-bottom px-0  custom-radio">
                                <input type="radio" id="customRadio2" name="location" class="custom-control-input"/>
                                <label class="custom-control-label py-3 w-100 px-3" for="customRadio2">Nearest Me</label>
                            </div>
                            <div class="custom-control border-bottom px-0  custom-radio">
                                <input type="radio" id="customRadio3" name="location" class="custom-control-input"/>
                                <label class="custom-control-label py-3 w-100 px-3" for="customRadio3">Cost High to Low</label>
                            </div>
                            <div class="custom-control border-bottom px-0  custom-radio">
                                <input type="radio" id="customRadio4" name="location" class="custom-control-input"/>
                                <label class="custom-control-label py-3 w-100 px-3" for="customRadio4">Cost Low to High</label>
                            </div>
                            <div class="custom-control border-bottom px-0  custom-radio">
                                <input type="radio" id="customRadio5" name="location" class="custom-control-input"/>
                                <label class="custom-control-label py-3 w-100 px-3" for="customRadio5">Most Popular</label>
                            </div>

                            <div class="p-3 bg-light border-bottom">
                                <h6 class="m-0">FILTER</h6>
                            </div>
                            <div class="custom-control border-bottom px-0  custom-checkbox">
                                <input type="checkbox" class="custom-control-input" id="defaultCheck1" checked/>
                                <label class="custom-control-label py-3 w-100 px-3" for="defaultCheck1">Open Now</label>
                            </div>
                            <div class="custom-control border-bottom px-0  custom-checkbox">
                                <input type="checkbox" class="custom-control-input" id="defaultCheck2"/>
                                <label class="custom-control-label py-3 w-100 px-3" for="defaultCheck2">Credit Cards</label>
                            </div>
                            <div class="custom-control border-bottom px-0  custom-checkbox">
                                <input type="checkbox" class="custom-control-input" id="defaultCheck3"/>
                                <label class="custom-control-label py-3 w-100 px-3" for="defaultCheck3">Alcohol Served</label>
                            </div>

                            <div class="p-3 bg-light border-bottom">
                                <h6 class="m-0">ADDITIONAL FILTERS</h6>
                            </div>
                            <div class="px-3 pt-3">
                                <input type="range" class="custom-range" min="0" max="100" name=""/>
                                <div class="form-row">
                                    <div class="form-group col-6">
                                        <label>Min</label>
                                        <input class="form-control" placeholder="&#8377; 0" type="number"/>
                                    </div>
                                    <div class="form-group text-right col-6">
                                        <label>Max</label>
                                        <input class="form-control" placeholder="&#8377; 1,0000" type="number"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer p-0 border-0">
                    <div class="col-6 m-0 p-0">
                        <button type="button" class="btn border-top btn-lg btn-block" onClick={this. onCloseModal}>Close</button>
                    </div>
                    <div class="col-6 m-0 p-0">
                        <button type="button" class="btn btn-success btn-lg btn-block">Apply</button>
                    </div>
                </div>
            </div>
        </div>
        </Modal>



    </div>
          
      )
    }
  }

  export default Productslists;