import React from 'react'
import Footer from './Footer'
import Header from "./Header";


class Account extends React.Component {
    render () {
      return (
          <div>
              <Header></Header>
              <div class="osahan-account">
        <div class="p-3 border-bottom">
            <div class="d-flex align-items-center">
                <h5 class="font-weight-bold m-0">My Account</h5>
                <a class="toggle ml-auto" href="#"><i class="icofont-navigation-menu"></i></a>
            </div>
        </div>
        <div class="p-4 profile text-center border-bottom">
            <img src="assets/img/user.jpg" class="img-fluid rounded-pill"/>
            <h6 class="font-weight-bold m-0 mt-2">Manjeet Kumar</h6>
            <p class="small text-muted"><a href="#">user@website.com</a></p>
            <a href="/Profile" class="btn btn-success btn-sm"><i class="icofont-pencil-alt-5"></i> Edit Profile</a>
        </div>
        <div class="account-sections">
            <ul class="list-group">
                <a href="#" class="text-decoration-none text-dark">
                    <li class="border-bottom bg-white d-flex align-items-center p-3">
                        <i class="icofont-sale-discount osahan-icofont bg-success"></i>Promos
                        <span class="badge badge-success p-1 badge-pill ml-auto"><i class="icofont-simple-right"></i></span>
                    </li>
                </a>
                <a href="/Editaddress" class="text-decoration-none text-dark">
                    <li class="border-bottom bg-white d-flex align-items-center p-3">
                        <i class="icofont-address-book osahan-icofont bg-dark"></i>My Address
                        <span class="badge badge-success p-1 badge-pill ml-auto"><i class="icofont-simple-right"></i></span>
                    </li>
                </a>
                <a href="#" class="text-decoration-none text-dark">
                    <li class="border-bottom bg-white d-flex align-items-center p-3">
                        <i class="icofont-info-circle osahan-icofont bg-primary"></i>Terms, Privacy & Policy
                        <span class="badge badge-success p-1 badge-pill ml-auto"><i class="icofont-simple-right"></i></span>
                    </li>
                </a>
                <a href="#" class="text-decoration-none text-dark">
                    <li class="border-bottom bg-white d-flex align-items-center p-3">
                        <i class="icofont-phone osahan-icofont bg-warning"></i>Help & Support
                        <span class="badge badge-success p-1 badge-pill ml-auto"><i class="icofont-simple-right"></i></span>
                    </li>
                </a>
                <a href="/login" class="text-decoration-none text-dark">
                    <li class="border-bottom bg-white d-flex  align-items-center p-3">
                        <i class="icofont-lock osahan-icofont bg-danger"></i> Logout
                    </li>
                </a>
            </ul>
        </div>
    </div>
              <Footer></Footer>
          </div>
      )
    }
  }

  export default Account;