import React from "react";
import Footer from "./Footer";
import Header from "./Header";



class Wishlist extends React.Component {
    render () {
      return (
        <div>
        <Header></Header>
        <div class="osahan-vegan">
        <div class="p-3 border-bottom bg-white">
            <div class="d-flex align-items-center">
                <a class="font-weight-bold text-success text-decoration-none" href="home.html">
                    <i class="icofont-rounded-left back-page"></i></a>
                    <span class="font-weight-bold ml-3 h6 mb-0">Favorite</span>
                    <a class="toggle ml-auto" href="#"><i class="icofont-navigation-menu"></i></a>
                </div>
            </div>

            <div class="fresh-vegan pb-2">
                <div class="d-flex align-items-center mt-3 mb-2 px-3">
                    <h6 class="m-0 font-weight-normal">Green Vegan</h6>
                    <a href="listing.html" class="ml-auto text-success">See more</a>
                </div>
                <div class="trending-slider">
                    <div class="osahan-slider-item m-2">
                        <div class="list-card bg-white h-100 rounded overflow-hidden position-relative shadow-sm">
                            <div class="list-card-osahan-2 p-3">
                                <div class="member-plan position-absolute"><span class="badge badge-danger">10%</span></div>
                                <a href="product_details.html" class="text-decoration-none text-dark">
                                    <img src="assets/img/green/g1.jpg" class="img"/>
                                    <h5 class="text-success">Broccoli</h5>
                                    <h6 class="mb-1 font-weight-bold">$0.14 <del class="small">$0.22</del></h6>
                                    <p class="text-gray mb-0 small">Fresh Broccoli item from Thailand.</p>
                                    <p class="small text-muted m-0 text-success">200 grm.</p>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="osahan-slider-item m-2">
                        <div class="list-card bg-white h-100 rounded overflow-hidden position-relative shadow-sm">
                            <div class="list-card-osahan-2 p-3">
                                <div class="member-plan position-absolute"><span class="badge badge-success">10%</span></div>
                                <a href="product_details.html" class="text-decoration-none text-dark">
                                    <img src="assets/img/green/g2.jpg" class="img"/>
                                    <h5 class="text-success">Spinach</h5>
                                    <h6 class="mb-1 font-weight-bold">$0.32</h6>
                                    <p class="text-gray mb-0 small">Fresh Broccoli item from Thailand.</p>
                                    <p class="small text-muted m-0 text-success">200 grm.</p>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="osahan-slider-item m-2">
                        <div class="list-card bg-white h-100 rounded overflow-hidden position-relative shadow-sm">
                            <div class="list-card-osahan-2 p-3">
                                <div class="member-plan position-absolute"><span class="badge badge-dark">10%</span></div>
                                <a href="product_details.html" class="text-decoration-none text-dark">
                                    <img src="assets/img/green/g3.jpg" class="img"/>
                                    <h5 class="text-success">Spring Onion</h5>
                                    <h6 class="mb-1 font-weight-bold">$0.8 <del class="small">$0.22</del></h6>
                                    <p class="text-gray mb-0 small">Fresh Broccoli item from Thailand.</p>
                                    <p class="small text-muted m-0 text-success">200 grm.</p>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="d-flex align-items-center mt-3 mb-2 px-3">
                    <h6 class="m-0 font-weight-normal">Daily Needs Vegan</h6>
                    <a href="listing.html" class="ml-auto text-success">See more</a>
                </div>
                <div class="trending-slider">
                    <div class="osahan-slider-item m-2">
                        <div class="list-card bg-white h-100 rounded overflow-hidden position-relative shadow-sm">
                            <div class="list-card-osahan-2 p-3">
                                <div class="member-plan position-absolute"><span class="badge badge-success">10%</span></div>
                                <a href="product_details.html" class="text-decoration-none text-dark">
                                    <img src="assets/img/green/g4.jpg" class="img"/>
                                    <h5 class="text-success">Onion</h5>
                                    <h6 class="mb-1 font-weight-bold">$0.14 <del class="small">$0.22</del></h6>
                                    <p class="text-gray mb-0 small">Fresh Onion Prem from Thailand.</p>
                                    <p class="small text-muted m-0 text-success">200 grm.</p>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="osahan-slider-item m-2">
                        <div class="list-card bg-white h-100 rounded overflow-hidden position-relative shadow-sm">
                            <div class="list-card-osahan-2 p-3">
                                <div class="member-plan position-absolute"><span class="badge badge-dark">10%</span></div>
                                <a href="product_details.html" class="text-decoration-none text-dark">
                                    <img src="assets/img/green/g5.jpg" class="img"/>
                                    <h5 class="text-success">Tomato</h5>
                                    <h6 class="mb-1 font-weight-bold">$0.32</h6>
                                    <p class="text-gray mb-0 small">Fresh Tomato item from Korea.</p>
                                    <p class="small text-muted m-0 text-success">200 grm.</p>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="osahan-slider-item m-2">
                        <div class="list-card bg-white h-100 rounded overflow-hidden position-relative shadow-sm">
                            <div class="list-card-osahan-2 p-3">
                                <div class="member-plan position-absolute"><span class="badge badge-dark">10%</span></div>
                                <a href="product_details.html" class="text-decoration-none text-dark">
                                    <img src="assets/img/green/g6.jpg" class="img"/>
                                    <h5 class="text-success">Carrot</h5>
                                    <h6 class="mb-1 font-weight-bold">$0.8 <del class="small">$0.22</del></h6>
                                    <p class="text-gray mb-0 small">Fresh Premium item from India.</p>
                                    <p class="small text-muted m-0 text-success">200 grm.</p>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="d-flex align-items-center mt-3 mb-2 px-3">
                    <h6 class="m-0 font-weight-normal">Others Vegan</h6>
                    <a href="listing.html" class="ml-auto text-success">See more</a>
                </div>
                <div class="trending-slider">
                    <div class="osahan-slider-item m-2">
                        <div class="list-card bg-white h-100 rounded overflow-hidden position-relative shadow-sm">
                            <div class="list-card-osahan-2 p-3">
                                <div class="member-plan position-absolute"><span class="badge badge-dark">10%</span></div>
                                <a href="product_details.html" class="text-decoration-none text-dark">
                                    <img src="assets/img/green/g7.jpg" class="img"/>
                                    <h5 class="text-success">Chilli</h5>
                                    <h6 class="mb-1 font-weight-bold">$0.14 <del class="small">$0.22</del></h6>
                                    <p class="text-gray mb-0 small">Fresh Premium item from India.</p>
                                    <p class="small text-muted m-0 text-success">200 grm.</p>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="osahan-slider-item m-2">
                        <div class="list-card bg-white h-100 rounded overflow-hidden position-relative shadow-sm">
                            <div class="list-card-osahan-2 p-3">
                                <div class="member-plan position-absolute"><span class="badge badge-dark">10%</span></div>
                                <a href="product_details.html" class="text-decoration-none text-dark">
                                    <img src="assets/img/green/g8.jpg" class="img"/>
                                    <h5 class="text-success">Cabbage</h5>
                                    <h6 class="mb-1 font-weight-bold">$0.32</h6>
                                    <p class="text-gray mb-0 small">Fresh Premium item from India.</p>
                                    <p class="small text-muted m-0 text-success">200 grm.</p>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="osahan-slider-item m-2">
                        <div class="list-card bg-white h-100 rounded overflow-hidden position-relative shadow-sm">
                            <div class="list-card-osahan-2 p-3">
                                <div class="member-plan position-absolute"><span class="badge badge-dark">10%</span></div>
                                <a href="product_details.html" class="text-decoration-none text-dark">
                                    <img src="assets/img/green/g9.jpg" class="img"/>
                                    <h5 class="text-success">Cauliflower</h5>
                                    <h6 class="mb-1 font-weight-bold">$0.8 <del class="small">$0.22</del></h6>
                                    <p class="text-gray mb-0 small">Fresh Premium item from India.</p>
                                    <p class="small text-muted m-0 text-success">200 grm.</p>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Footer></Footer>
      </div>
 
      )
    }
  }

  export default Wishlist;